// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apartments-js": () => import("./../src/pages/apartments.js" /* webpackChunkName: "component---src-pages-apartments-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-luxury-homes-js": () => import("./../src/pages/luxury-homes.js" /* webpackChunkName: "component---src-pages-luxury-homes-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reservation-policy-js": () => import("./../src/pages/reservation-policy.js" /* webpackChunkName: "component---src-pages-reservation-policy-js" */),
  "component---src-pages-rooms-js": () => import("./../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */)
}

